import WebApp from "@twa-dev/sdk";

export default {
  defaultLocale: "en",
  api: {
    production: "https://api.tonstars.app",
    test: location.origin + "/api",
  },
  refUrl:
    "https://t.me/ton_stars_app_bot/tonstars?startapp=id" +
    WebApp.initDataUnsafe.user?.id,
} as const;
